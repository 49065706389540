const dummyModels = [
    {
        id: 1,
        title: 'Model 1',
        environment: 'prod',
        workspace: 'WS 1',
        updatedDate: new Date("10/10/2004"),
        performance: [{ success: 94, failure: 6 }, { success: 85, failure: 15 }, { success: 74, failure: 26 }, { success: 100, failure: 0 }, { success: 55, failure: 45 }, { success: 100, failure: 0 }, { success: 55, failure: 45 }, { success: 100, failure: 0 }, { success: 55, failure: 45 }],
        collaborators: ["1", "2", "3"]
    },
    {
        id: 2,
        title: 'Simpsons Detector',
        environment: 'dev',
        updatedDate: new Date("10/10/2004"),
        performance: [{ success: 94, failure: 6 }, { success: 85, failure: 15 }, { success: 74, failure: 26 }, { success: 100, failure: 0 }, { success: 55, failure: 45 }],
        collaborators: ["1", "2", "3"]
    },
    {
        id: 3,
        title: 'Thug Detector',
        environment: 'prod',
        workspace: 'Test WS',
        updatedDate: new Date("10/10/2004"),
        performance: [{ success: 94, failure: 6 }, { success: 85, failure: 15 }, { success: 74, failure: 26 }, { success: 100, failure: 0 }, { success: 55, failure: 45 }],
        collaborators: ["1", "2", "3"]
    },
    {
        id: 4,
        title: 'Revenue detector',
        environment: 'staging',
        updatedDate: new Date("10/10/2004"),
        performance: [{ success: 94, failure: 6 }, { success: 85, failure: 15 }, { success: 74, failure: 26 }, { success: 100, failure: 0 }, { success: 55, failure: 45 }],
        collaborators: ["1", "2", "3"]
    },
    {
        id: 5,
        title: 'Art detector',
        environment: 'prod',
        workspace: 'WS 1',
        updatedDate: new Date("10/10/2004"),
        performance: [{ success: 94, failure: 6 }, { success: 85, failure: 15 }, { success: 74, failure: 26 }, { success: 100, failure: 0 }, { success: 55, failure: 45 }],
        collaborators: ["1", "2", "3"]
    },
];

export default dummyModels;
