import React, { useEffect, useState } from 'react'
import FormGroup from '../../../bootstrap/forms/FormGroup'
import CreatableSelect from 'react-select/creatable';
import { SearchEngineImage } from './SearchEngineImage';
import Label from '../../../bootstrap/forms/Label';
import Popovers from '../../../bootstrap/Popovers';
import Icon from '../../../icon/Icon';
import { Dots } from 'loading-animations-react';
import SearchService from '../../../../services/SearchService';
import './SearchEngineImage.css'

export const SearchEngineLabelInput = ({ label, index, updateLabel, deleteLabel }) => {

    const [searchEngineImages, setSearchEngineImages] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const isEmptyString = (value) => {
        return (!value || value == undefined || value == "" || value.length == 0)
    }

    useEffect(async () => {
        setIsLoading(true)
        console.log("Terms", label?.searchTerms)
        const searchTermStrings = label?.searchTerms?.map(s => s.value)?.filter(s => !isEmptyString(s))
        const searchEngineImages = await SearchService.getSearchImagesForTerms(searchTermStrings)
        setSearchEngineImages(searchEngineImages?.map(imageUrl => ({ enabled: true, url: imageUrl })))
        setIsLoading(false)
    }, [label?.searchTerms])

    const handleDelete = (index) => {
        var images = [...searchEngineImages]
        images[index].enabled = false
        setSearchEngineImages(images)
    }

    return (
        <>
            <div className='col-md-12'>
                <FormGroup id={`label-search-${index}`} label="Add search engine terms">
                    <CreatableSelect
                        options={[]}
                        isMulti
                        minMenuHeight={10}
                        placeholder="e.g. hotdog"
                        onChange={(newValue, actionMetaData) => {
                            console.debug(newValue)
                            updateLabel({ ...label, searchTerms: newValue }, index)
                        }}
                        form="false"
                        value={label.searchTerms}
                    />
                </FormGroup>
            </div>
            {
                isLoading ?
                    <><Dots></Dots></>
                    : label?.searchTerms && label?.searchTerms?.length > 0 ?
                        <div className='col-md-12'>
                            <Label title={"Examples"}>Search Engine Example Results</Label>
                            <Popovers
                                trigger='hover'
                                desc={`Images like this will be classified by your model as "${label?.title}"`}>
                                <Icon
                                    icon='InfoCircle'
                                    size='lg'
                                    className='ms-1 cursor-help'
                                />
                            </Popovers>
                            <div className='scrolling-list' >
                                <div className='list-scroller' >
                                    {
                                        searchEngineImages?.map((image, index) => {
                                                return image?.enabled ? (
                                                    <div className='mr-3 outer-list' key={index}>
                                                        <SearchEngineImage index={index} handleDelete={handleDelete} image={image} />
                                                    </div>
                                                ) : <></>
                                            })
                                    }
                                </div >
                            </div >
                        </div> :
                        <></>
            }
        </>
    )
}
