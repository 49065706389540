import { getJson, putJson } from "./ServiceHelperMethods";

class ModelLabelService {

    static async getModelLabel(model) {
        return await getJson(`models/${model._id}/labels`);
    }

    static async putModelLabels(model, labels) {
        return await putJson(`models/${model._id}/labels`, labels);
    }
}

export default ModelLabelService;