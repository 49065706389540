import { dummyWorkspaceData } from "../common/data/test_workspaceData";
import { getJson, putJson, deleteJson, postJson } from "./ServiceHelperMethods";

class WorkspaceService {

    static async getWorkspaces() {
        console.log("Is function being called?")
        return await getJson(`workspaces`);
    }

    static async getWorkspace(workspaceId) {
        return await getJson(`workspaces/${workspaceId}`);
    }

    static async createWorkspaces(workspaces) {
        return await putJson(`workspaces`, workspaces);
    }

    static async createWorkspace(workspace) {
        const workspaces = await WorkspaceService.createWorkspaces([workspace]);
        return workspaces[0]
    }

    static async deleteWorkspace(workspaceId) {
        return await deleteJson(`workspaces/${workspaceId}`);
    }

    static async startWorkspaceUpgradePaymentIntent(workspace, price) {
        return await postJson(`workspaces/${workspace._id}/initializeUpgrade`, { priceId: price.id });
    }

    static async completeUpgrade(workspace, paymentIntent, subscriptionId) {
        return await postJson(`workspaces/${workspace._id}/completeUpgrade`, { paymentIntent: paymentIntent.id, subscriptionId: subscriptionId });
    }
}



export default WorkspaceService;