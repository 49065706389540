import React from 'react'
import CreatableSelect from 'react-select/creatable';
import { createFilter } from 'react-select';
import a4Logger from '../../../services/a4Logger';
import UserService from '../../../services/UserService';

const filter = {
    // default filter configuration
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    // stringify: option => `${option.data.name} ${option.data.email}`,
    trim: true,
}

export const AvatarMemberInput = ({userOptions, updateMembers}) => {

    const isEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const _updateMembers = async (newValue) => {

        if (!newValue.user_id) {
            if (!isEmail(newValue.label)) {
                a4Logger.logError(`Invitee must be an email address`)
                return
            }
            else {
                newValue = await UserService.getOrCreateUser(newValue.label)
                DelphiLogger.logError(`Invite sent to ${newValue.email}`)
            }
        }

        const newMembers = [...new Set([...(members ?? []), newValue.user_id])]
        updateMembers(newMembers)
    }


    return (
        // style={{ width: "50%", paddingBottom: "70px" }}
        <div >
            <CreatableSelect
                placeholder="+Add Member"
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                options={[{email: "dfdfdf", name: "dfdfdfdf"}, { email: "dfdfdf", name: "dfdfdfdf" }]}
                minMenuHeight={10}
                onChange={async (newValue, actionMetaData) => await _updateMembers(newValue, actionMetaData)}
                form="false"
                value={null}
                filterOption={createFilter(filter)}
                // isClearable={true}
                formatCreateLabel={inputValue => `Press enter to invite '${inputValue}' by email`}
            />
        </div>
    )
}
