import { useTour } from '@reactour/tour'
import React from 'react'
import start from '../../../../assets/img/scene8.png'
import Button from '../../../bootstrap/Button'
import Icon from '../../../icon/Icon'

export const TourSlide = () => {
    const { setIsOpen } = useTour();
    return (
        <div className='row'>
            <div className='col-12 col-md-9'>
                <h1>Take A Look Around</h1>
                <h2 style={{ fontWeight: 200 }}>
                    Take a guided tour of the platform at any time using the tour button <Icon icon="Tour" />  on the top menu.
                    <br />
                    <br />
                    <Button
                        color="success"
                        icon="Tour"
                        className="pulse-button float-right"
                        onClick={() => setIsOpen(true)}
                    >
                        Take Tour
                    </Button>
                </h2>
            </div>
            <div className='col-0 col-md-3 d-none d-md-block'>
                <img
                    width="300px"
                    src={start}
                />
            </div>
        </div>
    )
}
