import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { WizardGenerator } from '../../../WizardGenerator/WizardGenerator';
// import { LabelInput } from './ImageRecognition/LabelInput';
import { useDispatch, useSelector } from 'react-redux';
import { createModels } from '../../../../redux/slices/ModelSlice';
import { createWorkspaces, getWorkspaces } from '../../../../redux/slices/WorkspaceSlice';
import ModelLabelService from '../../../../services/ModelLabelService';
import UserService from '../../../../services/UserService';
import showNotification from '../../../extras/showNotification';
import { NewModelTypeSelection } from './NewModelTypeSelection';
import { LabelTagInput } from '../ImageRecognition/LabelTagInput';
import { LabelInput } from '../ImageRecognition/LabelInput';
import { NewModelIntro } from './NewModelIntro';
import { incrementModelCount } from '../../../../redux/slices/UserSlice';

export const NewModelEasyWizard = ({ workspaces, callback }) => {

    const { modelType } = useParams();
    const navigate = useNavigate();

    const user = useSelector((state) => state.user.me)
    const [isLoading, setIsLoading] = useState(true);
    const [wizardConfig, setWizardConfig] = useState(null)
    const [labels, setLabels] = useState([])

    const dispatch = useDispatch();
    const accountState = useSelector((state) => state.account.myAccounts)
    const modelTypeState = useSelector((state) => state.modeltype)
    const [wizardPageIndex, setWizardPageIndex] = useState(0)
    function capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const createModel = (formData, workspaceId) => {
        const model = {
            title: formData?.title,
            collaborators: [user?.value?.user_id],
            workspace: workspaceId,
        }

        return model
    }

    const updateImageRecognitionModel = (formData, inputModel) => {
        const model = {
            ...inputModel,

            modelType: formData?.modelType?._id,
            status: "queued",
            trainingDataTypes: ["64038fcba1d1e584eb053cd6", "64038fcca1d1e584eb053cd9"],
            inferenceInputDataTypes: ["64038fcba1d1e584eb053cd6", "64038fcca1d1e584eb053cd9"],
        }

        console.log("Creating new model", model)
        return model
    }

    const createImageRecognitionLabels = async (formData, model) => {
        const labelsToPut = formData?.labels?.map(label => {
            return {
                model: model?._id,
                title: label?.title,
                description: label?.description,
                searchTerms: label?.searchTerms?.map(s => s.value),
                // datasets: [],
            }
        }) ?? []

        console.log("Labels to put", labelsToPut)
        return labelsToPut

    }

    const submitModel = async (formData, user) => {
        console.log("Submit Model", formData)
        console.log("User", user)

        var workspaceId = formData?.workspace?._id

        if (formData?.workspace?.__isNew__) {
            const accountId = accountState?.value?.[0]?._id
            const newWorkspace = {
                title: formData?.workspace?.label,
                account: accountId,
                collaborators: [user?.value?.user_id],
                health: "healthy",
            }

            console.log("New workspace to create", newWorkspace)
            const workspaces = await dispatch(createWorkspaces([newWorkspace]));
            const workspace = workspaces?.payload?.[0]
            console.log("Hydrated workspace", workspace)

            workspaceId = workspace?._id
        }

        var model = createModel(formData, workspaceId)
        model.workspace = workspaceId
        model.collaborators = [user?.value?.user_id]

        if (formData?.modelType?.title == "Image Recognition") {
            model = updateImageRecognitionModel(formData, model)
        }
        else {
            showNotification(
                "ERROR: Model Type Not Supported",
                "The model type you selected is not supported at this time. Please select a different model type such as Image Recognition.",
            )
            return
        }


        console.log("Creating new model", model)
        const newModels = await dispatch(createModels([model]))
        console.log("Hydrated Models", newModels)
        const newModel = newModels?.payload?.[0]
        console.log("Hydrated model", newModels)


        if (formData?.modelType?.title == "Image Recognition") {
            const newLabels = await createImageRecognitionLabels(formData, newModel)
            console.log("Creating new labels", newLabels)
            const hydratedLabels = await ModelLabelService.putModelLabels(newModel, newLabels)
            console.log("Hydrated Labels", hydratedLabels)

        }

        dispatch(incrementModelCount())
        dispatch(getWorkspaces())

        navigate(`/model/${newModel?._id}`)
        if (callback) {
            callback(newModel)
        }
    }


    const updateConfig = async () => {
        setWizardConfig({
            onSubmit: async formData => await submitModel(formData, user),
            steps: [
                {
                    icon: "Box",
                    iconColor: "success",
                    cards: [
                        {
                            title: "",
                            fields: [
                                {
                                    id: "intro",
                                    title: "Model Type",
                                    render: (formik) => {
                                        return <NewModelIntro />
                                    }
                                },
                            ]
                        },
                    ]
                },
                {
                    title: "Create a New AI Model",
                    icon: "Box",
                    iconColor: "success",
                    cards: [
                        {
                            title: "Select The Type of AI That You Want To Create",
                            fields: [
                                {
                                    id: "modelType",
                                    title: "Model Type",
                                    validate: Yup.object().required("You must select a model type"),
                                    render: (formik) => {
                                        return <NewModelTypeSelection
                                            workingGroupList={formik.values?.modelType}
                                            updateModelType={(l) => {
                                                formik.setFieldValue("modelType", l)
                                                setWizardPageIndex(2)
                                            }}
                                        />
                                    }
                                },
                            ]
                        },
                    ]
                },
                {
                    title: "Define your labels: What objects do you want to recognize?",
                    icon: "Eye",
                    iconColor: "danger",
                    cards: [
                        {
                            title: "Define your labels: What objects do you want to recognize?",
                            fields: [
                                {
                                    id: "labelTags",
                                    title: "Labels (Things To Recognize)",
                                    render: (formik) => {
                                        return <LabelTagInput
                                            labels={formik.values?.labels}
                                            updateLabels={(l) => {
                                                formik.setFieldValue("labels", l)
                                            }}
                                        />
                                    }
                                },
                            ]
                        },
                    ]
                },
                {
                    title: "Add Data To Train Your Model",
                    icon: "Files",
                    iconColor: "warning",
                    cards: [
                        {
                            title: "Add Data To Train Your Model",
                            fields: [
                                {
                                    id: "labels",
                                    validate: Yup.array().min(2, "You must have at least two labels").required("You must have at least two labels"),
                                    title: "Labels (Things To Recognize)",
                                    render: (formik) => {
                                        return <div className='mt-0'>
                                            <p>
                                                In order for your AI model to recognize the labels that you have provided, the AI must first be trained on examples of those labels. For example, if you want to train your AI to recognize <b>dogs</b>, you must first provide example images of <b>dogs</b> and label them as dogs so that the AI can learn.<br/><br/>
                                                To speed up the process, you can use the images provided by our integrated data catalog. These images tend to be sufficient for basic use cases without any needed alterations. You can also upload your own training data if you have it.
                                            </p>
                                            <LabelInput
                                                labels={formik.values?.labels}
                                                updateLabels={(l) => {
                                                    formik.setFieldValue("labels", l)
                                                }}
                                            />
                                        </div>
                                    }
                                },
                            ]
                        },
                    ]
                },
                {
                    title: "Add data to train your model",
                    icon: "Files",
                    iconColor: "warning",
                    cards: [
                        {
                            title: "Name Your New Model",
                            fields: [
                                {
                                    id: "title",
                                    title: "Model Name",
                                    type: "input",
                                    init: "My Model",
                                    validate: Yup.string().required("Model name is required"),
                                    placeHolder: `ex. Hotdog Detector`
                                },
                                {
                                    visible: () => workspaces?.length > 1,
                                    id: "workspace",
                                    title: `Workspace (Select or Create)`,
                                    type: "create-select",
                                    createLabel: "Create new workspace",
                                    validate: Yup.object().required("You must select a workspace"),
                                    init: { ...workspaces[0], label: `${workspaces[0]?.title} (${workspaces[0]?.subscription?.tier ? capitalize(workspaces[0]?.subscription?.tier) : "Free"})`, value: workspaces[0]?._id },
                                    options: workspaces?.map(x => ({ ...x, label: `${x?.title} (${x?.subscription?.tier ? capitalize(x?.subscription?.tier) : "Free"})`, value: x?._id })) ?? []
                                },
                            ]
                        },
                    ]
                }
            ]
        })
    }

    useEffect(async () => {
        setIsLoading(true)
        await updateConfig()
        setIsLoading(false)
    }, [workspaces, accountState, modelTypeState, labels, user]);

    return (
        wizardConfig &&
        <WizardGenerator
            extIndex={wizardPageIndex}
            setExtIndex={setWizardPageIndex}
            wizardObject={wizardConfig} />
    )
}