import React from 'react'
import { NewModelEasyWizard } from '../../../components/models/NewModel/NewModelEasyWizard/NewModelEasyWizard'
import Page from '../../../layout/Page/Page'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import { useSelector } from 'react-redux'

export const NewModelEasyPageWizardPage = () => {

    const workspacesState = useSelector(state => state.workspace.myWorkspaces?.value)

    return <PageWrapper title="Let's Build Your New Model!">
        <Page>
            {
                (workspacesState?.length > 0) && <NewModelEasyWizard workspaces={workspacesState} />
            }
        </Page>
    </PageWrapper>
}
