import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Modal, {
    ModalBody, ModalHeader
} from '../../bootstrap/Modal';
import {
    OffCanvasTitle
} from '../../bootstrap/OffCanvas';
import { WizardGenerator } from '../../WizardGenerator/WizardGenerator';
import { LabelInput } from './ImageRecognition/LabelInput';
import { useSelector, useDispatch } from 'react-redux';
import UserService from '../../../services/UserService';
import WorkspaceService from '../../../services/WorkspaceService';
import showNotification from '../../extras/showNotification';
import { createModels } from '../../../redux/slices/ModelSlice';
import ModelLabelService from '../../../services/ModelLabelService';
import { createWorkspaces, getWorkspaces } from '../../../redux/slices/WorkspaceSlice';
import { incrementModelCount } from '../../../redux/slices/UserSlice';

export const NewModelModal = ({ isNewModelModalOpen, setIsNewModelModalOpen, curProject, callback }) => {

    const projectId = curProject?._id

    const [isLoading, setIsLoading] = useState(true);
    const [wizardConfig, setWizardConfig] = useState(null)

    const user = useSelector((state) => state.user.me);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accountState = useSelector((state) => state.account.myAccounts)
    const modelTypeState = useSelector((state) => state.modeltype)

    function capitalize(string) {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
    }

    const workspacesState = useSelector(state => state.workspace.myWorkspaces)

    const createModel = (formData, workspaceId) => {
        const model = {
            title: formData?.title,
            description: formData?.description,
            collaborators: [user?.value?.user_id],
            tags: formData?.tags?.map(x => x?.value),
            workspace: workspaceId,
        }

        return model
    }

    const updateImageRecognitionModel = (formData, inputModel) => {
        const model = {
            ...inputModel,

            modelType: formData?.modelType?._id,
            status: "queued",
            trainingDataTypes: ["64038fcba1d1e584eb053cd6", "64038fcca1d1e584eb053cd9"],
            inferenceInputDataTypes: ["64038fcba1d1e584eb053cd6", "64038fcca1d1e584eb053cd9"],
        }

        console.log("Creating new model", model)
        return model
    }

    const createImageRecognitionLabels = async (formData, model) => {
        const labelsToPut = formData?.labels?.map(label => {
            return {
                model: model?._id,
                title: label?.title,
                description: label?.description,
                searchTerms: label?.searchTerms?.map(s => s.value),
                // datasets: [],
            }
        }) ?? []

        console.log("Labels to put", labelsToPut)
        return labelsToPut

    }

    const submitModel = async (formData, user) => {
        console.log("Submit Model", formData)
        console.log("User", user)

        var workspaceId = formData?.workspace?._id

        if (formData?.workspace?.__isNew__) {
            const accountId = accountState?.value?.[0]?._id
            const newWorkspace = {
                title: formData?.workspace?.label,
                account: accountId,
                collaborators: [user?.value?.user_id],
                health: "healthy",
            }

            console.log("New workspace to create", newWorkspace)
            const workspaces = await dispatch(createWorkspaces([newWorkspace]));
            const workspace = workspaces?.payload?.[0]
            console.log("Hydrated workspace", workspace)

            workspaceId = workspace?._id
        }

        var model = createModel(formData, workspaceId)
        model.workspace = workspaceId
        model.collaborators = [user?.value?.user_id]

        if (formData?.modelType?.title == "Image Recognition") {
            model = updateImageRecognitionModel(formData, model)
        }
        else {
            showNotification(
                "ERROR: Model Type Not Supported",
                "The model type you selected is not supported at this time. Please select a different model type such as Image Recognition.",
            )
            return
        }


        console.log("Creating new model", model)
        const newModels = await dispatch(createModels([model]))
        console.log("Hydrated Models", newModels)
        const newModel = newModels?.payload?.[0]
        console.log("Hydrated model", newModels)


        if (formData?.modelType?.title == "Image Recognition") {
            const newLabels = await createImageRecognitionLabels(formData, newModel)
            console.log("Creating new labels", newLabels)
            const hydratedLabels = await ModelLabelService.putModelLabels(newModel, newLabels)
            console.log("Hydrated Labels", hydratedLabels)

        }

        dispatch(incrementModelCount())
        setIsNewModelModalOpen(false);
        dispatch(getWorkspaces())

        navigate(`/model/${newModel?._id}`)
    }


    const updateConfig = async () => {
        setWizardConfig({
            onSubmit: async formData => await submitModel(formData, user),
            steps: [
                {
                    title: "New Model",
                    icon: "Box",
                    iconColor: "success",
                    cards: [
                        {
                            title: "Model Information",
                            fields: [
                                {
                                    id: "workspace",
                                    title: `Workspace (Select or Create)`,
                                    type: "create-select",
                                    createLabel: "Create new workspace",
                                    validate: Yup.object().required("You must select a workspace"),
                                    options: workspacesState?.value?.map(x => ({ ...x, label: `${x?.title} (${x?.subscription?.tier ? capitalize(x?.subscription?.tier) : "Free"})`, value: x?._id })) ?? []
                                },
                                {
                                    id: "title",
                                    title: "Model Name",
                                    type: "input",
                                    validate: Yup.string().required("Model name is required"),
                                    placeHolder: `ex. Hotdog Detector`
                                },
                                {
                                    id: "modelType",
                                    title: `Model Type`,
                                    type: "select",
                                    validate: Yup.object().required("You must select a model type"),
                                    options: modelTypeState?.value?.map(x => ({ ...x, label: x?.title, value: x?._id })) ?? []
                                },
                                {
                                    id: "dataType",
                                    title: `Data Type`,
                                    type: "select",
                                    validate: Yup.object().required("You must select a data type"),
                                    init: { label: "Images", value: "1" },
                                    options: [{ label: "Images", value: "1" }]
                                },
                            ]
                        },
                        {
                            title: "Details (Optional)",
                            collapsed: true,
                            fields: [
                                {
                                    id: "description",
                                    title: "Description",
                                    type: "textarea",
                                    rows: 2,
                                    placeholder: "ex. Differentiates between hotdogs and not-hotdogs"
                                },
                                {
                                    id: "tags",
                                    title: `Tags`,
                                    type: "create-select-multi",
                                    init: []
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Add Labels (Things To Recognize)",
                    icon: "Eye",
                    iconColor: "danger",
                    cards: [
                        {
                            title: "Model Information",
                            fields: [
                                {
                                    id: "labels",
                                    title: "Labels (Things To Recognize)",
                                    validate: Yup.array().min(1, "You must have at least one label").required("You must have at least one label"),
                                    init: [{}],
                                    render: (formik) => {
                                        return <LabelInput
                                            labels={formik.values?.labels}
                                            updateLabels={(l) => {
                                                formik.setFieldValue("labels", l)
                                            }}
                                        />
                                    }
                                }
                            ]
                        },
                    ]
                },
            ]
        })
    }

    useEffect(async () => {
        setIsLoading(true)
        await updateConfig()
        setIsLoading(false)
    }, [workspacesState, accountState, modelTypeState, user]);

    return (
        <Modal
            setIsOpen={setIsNewModelModalOpen}
            isOpen={isNewModelModalOpen}
            titleId='upcomingEdit'
            isScrollable={false}
            size='xl'
            fullScreen='xxl'
        >
            <ModalHeader setIsOpen={setIsNewModelModalOpen}>
            </ModalHeader>
            <ModalBody>
                {
                    wizardConfig && <WizardGenerator
                        wizardObject={wizardConfig}
                    />
                }
            </ModalBody>
        </Modal>
    )
}