import { getJson, putJson } from './ServiceHelperMethods';

class AccountService {

    static async getAccounts() {
        return await getJson(`accounts`);
    }

    static async createAccounts(accounts) {
        return await putJson(`accounts`, accounts);
    }
}

export default AccountService;