import UserService from "./UserService"

export const get = async (path) => {

    const additonalHeaders = await UserService.getUserHeader()

    const response = await fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/${path}`, {
        method: 'GET',
        headers: {
            ...additonalHeaders
        },
    })
    return response
}

export const getJson = async (path) => {

    console.log("Getting headers?")
    const additonalHeaders = await UserService.getUserHeader()
    console.log("Headers: ", additonalHeaders)

    const response = await fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/${path}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...additonalHeaders
        },
    })
    const data = await response.json()
    return data
}

export const putJson = async (path, body, baseUrl = process.env.REACT_APP_PUBLIC_API_URL, additionalHeaders = {}) => {

    const additonalHeaders = await UserService.getUserHeader()

    const response = await fetch(`${baseUrl}/${path}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...additonalHeaders
        },
        body: JSON.stringify(body)
    })
    const data = await response.json()
    return data
}

export const postJson = async (path, body, baseUrl = process.env.REACT_APP_PUBLIC_API_URL, additionalHeaders = {}) => {

    const additonalHeaders = await UserService.getUserHeader()

    const response = await fetch(`${baseUrl}/${path}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...additonalHeaders
        },
        body: JSON.stringify(body)
    })
    const data = await response.json()
    return data
}

export const deleteJson = async (path, body, baseUrl = process.env.REACT_APP_PUBLIC_API_URL, additionalHeaders = {}) => {

    const additonalHeaders = await UserService.getUserHeader()

    const response = await fetch(`${baseUrl}/${path}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            ...additonalHeaders
        },
        body: body ? JSON.stringify(body) : undefined
    })
    const data = await response.json()
    return data
}

export const postRaw = async (path, body, baseUrl = process.env.REACT_APP_PUBLIC_API_URL, additionalHeaders = {}) => {

    const additonalHeaders = await UserService.getUserHeader()

    const response = await fetch(`${baseUrl}/${path}`, {
        method: 'POST',
        headers: {
            ...additonalHeaders
        },
        body: body
    })
    const data = await response.json()
    return data
}