import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import DataTypeService from '../../services/DataTypeService'

export const getDataTypes = createAsyncThunk('datatypes/getDataTypes', async (workspaceId) => {
    const datatypes = await DataTypeService.getDataTypes(workspaceId);
    return datatypes
})

const initialState = {
    value: [],
    loading: false,
    error: null
}

export const DataTypeSlice = createSlice({
    name: 'datatype',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDataTypes.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(getDataTypes.fulfilled, (state, action) => {
                state.loading = false
                state.value = action.payload
            })
            .addCase(getDataTypes.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
    },
})

// Action creators are generated for each case reducer function
export const selectDataTypes = (state) => state.datatype.value
export const selectLoading = (state) => state.datatype.loading
export const selectError = (state) => state.datatype.error
export default DataTypeSlice.reducer