import dummyModels from "../common/data/dummyModelsData";
import a4Logger from "./a4Logger";
import { get, getJson, postRaw, putJson, deleteJson } from "./ServiceHelperMethods";


class ModelService {

    static async getModels() {
        return await getJson(`models`);
    }

    static async getModel(modelId) {
        return await getJson(`models/${modelId}`);
    }

    static async putModels(models) {
        return await putJson(`models`, models);
    }

    static async deleteModel(modelId) {
        return await deleteJson(`models/${modelId}`);
    }

    static async downloadModel(model, link) {
        if (link.current.href) { return }

        const modelId = model?._id
        a4Logger.notify("Download started",`Downloading model "${model?.title}". This may take a few minutes.`)

        const current = link.current

        const resp = await get(`models/download/${modelId}`);

        const blob = await resp.blob()
        const href = window.URL.createObjectURL(blob)

        current.download = `${model?.title}.onnx`
        current.href = href

        current.click()
    }

    static async inferImage(model, imageData) {

        var fd = new FormData();
        fd.append('file', imageData, 'file.jpg');

        const modelIdentifiers = `model=${model?._id}`
        return await postRaw(`ImageClassifier?${modelIdentifiers}`, fd, process.env.REACT_APP_MODEL_TEST_API);

    }
}



export default ModelService;