import { getJson } from "./ServiceHelperMethods";

class ModelTypeService {

    static async getModelTypes() {

        return await getJson(`modeltypes`);

    }
}

export default ModelTypeService;