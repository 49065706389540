import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dots } from 'loading-animations-react';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../bootstrap/Card';
import { PriceSelector } from './PriceSelector';
import Icon from '../../icon/Icon';
import WorkspaceService from '../../../services/WorkspaceService';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { WorkspacePaymentSelector } from './WorkspacePaymentSelector';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkspaces } from '../../../redux/slices/WorkspaceSlice';
import { getModels } from '../../../redux/slices/ModelSlice';
import a4Logger from '../../../services/a4Logger';
import Auth0ClientService from '../../../services/Auth0ClientService';

export const UpgradeWorkspaceWizard = ({ workspace, model, message, completeUpgrade }) => {
    const [stepNumber, setStepNumber] = useState(0)

    const [subscriptionId, setSubscriptionId] = useState(null)
    const [stripeContext, setStripeContext] = useState(null)
    const [clientSecret, setClientSecret] = useState(null)
    const [paymentMethods, setPaymentMethods] = useState([])
    const [stripePromise, setStripePromise] = useState(null)

    const user = useSelector((state) => state.user.me?.value)

    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectTier = async (tier) => {
        setIsLoading(true)
        try {
            if (user?.isFalseUser) {
                Auth0ClientService.login(`/upgradeWorkspace?workspace=${workspace._id}&model=${model._id}`)
                return
            }

            setTier(tier)
            const stripeContext = await WorkspaceService.startWorkspaceUpgradePaymentIntent(workspace, tier)
            const { subscriptionId, clientSecret, publishableKey, paymentMethods } = stripeContext

            setStripeContext(stripeContext)
            setSubscriptionId(subscriptionId)
            setClientSecret(clientSecret)
            setPaymentMethods(paymentMethods)
            setStripePromise(loadStripe(publishableKey))


        }
        catch (e) {
            a4Logger.logError("An error occurred", error.message)
        }
        setIsLoading(false)
        setStepNumber(stepNumber + 1)
    }

    const [tier, setTier] = useState(null)

    const renderWizardStep = stepNumber => {
        switch (stepNumber) {
            case 0:
                return <PriceSelector setPlan={selectTier} />
            case 1:
                return <Elements stripe={stripePromise}>
                    <WorkspacePaymentSelector
                        completePayment={completeUpgrade}
                        stripeContext={stripeContext}
                        clientSecret={clientSecret}
                        workspace={workspace}
                        tier={tier}
                        paymentMethods={paymentMethods}
                        setIsLoading={setIsLoading}
                        setStepNumber={setStepNumber} />
                </Elements>
            default:
                <div>Unknown step</div>
        }
    }


    return (
        <Card stretch>
            <CardHeader tag='h4'>
                <div className='col-12 mb-3'>
                    <div className='display-5 fw-bold py-3'>
                        {message}
                    </div>
                </div>
            </CardHeader>
            <CardBody>
                {
                    isLoading ? <Dots /> : renderWizardStep(stepNumber)
                }
            </CardBody>
        </Card>
    )
}