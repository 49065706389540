import React from 'react'
import Button from '../../../bootstrap/Button'
import { LabelItem } from './LabelItem'

export const LabelInput = ({ labels, updateLabels }) => {

    const updateLabel = (label, index) => {
        const newLabels = [...labels]
        newLabels[index] = label
        updateLabels(newLabels)

        console.log(newLabels)
    }

    const deleteLabel = (index) => {
        const newLabels = [...labels]
        newLabels.splice(index, 1)
        updateLabels(newLabels)
    }


    return (
        <div>
            <div>
                {
                    labels?.map((label, index) => (
                        <LabelItem
                            key={index}
                            label={label}
                            index={index}
                            updateLabel={updateLabel}
                            deleteLabel={deleteLabel}
                        />))
                }
            </div>
            <div>
                <Button
                    color="success"
                    icon="PlusLg"
                    onClick={() => updateLabels([...(labels || []), {}])}
                >
                    Add Label
                </Button>
            </div>
        </div>
    )
}
