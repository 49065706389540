import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import './i18n';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-2YZPVNK9CX', {
	debug: true,
	titleCase: false
});

ReactDOM.render(
	<Router
		basename={process.env.PUBLIC_URL}
	>
		<React.StrictMode>
			<Provider store={store}>
				<ThemeContextProvider>
					<App />
				</ThemeContextProvider>
			</Provider>
		</React.StrictMode>
	</Router>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
