import React from 'react'
import CreatableSelect from 'react-select/creatable';

export const LabelTagInput = ({ labels, updateLabels }) => {
    return (
        <div>
            <div className='row mb-3'>
                <div className='col-12 col-md-4 d-flex justify-content-center'>
                    <img
                        src="https://miro.medium.com/v2/resize:fit:640/1*NLnnf_M4Nlm4p1GAWrWUCQ.gif"
                        alt="placeholder"
                        style={{ width: "100%" }} />
                </div>
                <div className='col-21 col-md-8'>
                    <p>
                        In image recognition, labels are essentially <b>categories</b> or <b>tags</b> that are assigned to images. They are used to describe what is depicted in the image or to indicate specific characteristics or objects present in the image. <br /> <br />
                        For example, let's say we have a collection of images of animals. To create a model that can recognize these animals, we need to train it using labeled data. Each image would be labeled with the corresponding animal it represents, such as <b>"cat"</b>, <b>"dog"</b>, <b>"elephant"</b>, and <b>"bird"</b>.˝
                    </p>
                </div>
            </div>
            <CreatableSelect
                // styles={creatableStyle}
                isMulti
                options={[]}
                placeholder="e.g. hotdog, hamburger"
                onChange={(newValue, actionMetaData) => {
                    const newLabels = newValue?.map((tag) => ({
                        title: tag.value,
                        datasource: "searchEngine",
                        searchTerms: [tag]
                    }))
                    console.log("New Labels", newLabels)
                    if (updateLabels) {
                        updateLabels(newLabels)
                    }
                }}
                form="false"
                value={labels?.map((label) => ({ value: label.title, label: label.title })) ?? []}
                formatCreateLabel={inputValue => `Press enter to add label '${inputValue}'`}
            />
        </div>
    )
}
