import React, { useState } from 'react'
import Button from '../../../bootstrap/Button';

import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
} from '../../../bootstrap/Card';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';
import Select from '../../../bootstrap/forms/Select';
import { SearchEngineLabelInput } from './SearchEngineLabelInput';

export const LabelItem = ({ label, index, updateLabel, deleteLabel }) => {

    const isNullOrEmpty = (value) => {
        return (!value || value == undefined || value == "" || value.length == 0)
    }
    
    return (
        <div>
            <Card className="mt-0" stretch>
                <CardHeader className='bg-transparent'>
                    <CardLabel>
                        <CardTitle tag='h4' className='h5'>
                            {isNullOrEmpty(label?.title) ? "New Label" : label?.title}
                        </CardTitle>
                    </CardLabel>
                    <CardActions>
                        <Button
                            icon='XLg'
                            aria-label='Remove Label'
                            hoverShadow='default'
                            color='danger'
                            onClick={() => { deleteLabel(index) }}
                        />
                    </CardActions>
                </CardHeader>
                <CardBody>
                    <div className='row g-4'>
                        <div className='col-md-12'>
                            <FormGroup id={`label-title-${index}`} label="Label Name" isFloating>
                                <Input
                                    placeholder="e.g. Hotdog"
                                    value={label?.title}
                                    onChange={e => {
                                        updateLabel({ ...label, title: e.target.value }, index)
                                    }}
                                    onBlur={e => {
                                        var lowercase = e.target.value?.toLowerCase();
                                        updateLabel({ ...label, searchTerms: [...(label?.searchTerms ?? []), { value: lowercase, label: lowercase }] }, index)
                                    }
                                    }
                                // validFeedback='Looks good!'
                                />
                            </FormGroup>
                        </div>
                        <div className='col-md-12'>
                            <FormGroup id={`label-datasource-${index}`} label="Data Source" isFloating>
                                <Select
                                    onChange={e => {
                                        updateLabel({ ...label, datasource: e.target.value }, index)
                                    }}
                                    value={label?.datasource}
                                >
                                    <option value="searchEngine">Search Engine</option>
                                    <option value="upload">Upload Data</option>
                                </Select>
                            </FormGroup>
                        </div>
                        {
                            isNullOrEmpty(label?.datasource) ? <></> :
                                (label?.datasource == "upload" ?
                                    <>Upload</> :
                                    <SearchEngineLabelInput label={label} index={index} updateLabel={updateLabel} deleteLabel={deleteLabel} />)
                        }
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}
