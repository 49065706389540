import React from 'react'
import './SearchEngineImage.css'

import Button from '../../../bootstrap/Button'

export const SearchEngineImage = ({ image, index, handleDelete, width = 150, height = 150 }) => {
    return (
        <div className='image-w-button'>
            <div class="image-w-button-overlay">
                <Button color="danger" icon="XLg" onClick={() => handleDelete(index)}>

                </Button>
            </div>
            <img
                width={width}
                height={height}
                src={image?.url}
                className='img-responsive search-engine-image full-width'
            >
            </img>
        </div>
    )
}
