import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import AccountService from '../../services/AccountService'

export const getAccounts = createAsyncThunk('accounts/getAccounts', async () => {
    const accounts = await AccountService.getAccounts();
    return accounts
})

export const createAccounts = createAsyncThunk('accounts/createAccounts', async (accounts) => {
    const hydratedAccounts = await AccountService.createAccounts(accounts);
    return hydratedAccounts
})

const initialState = {
    myAccounts: {
        value: [],
        loading: false,
        error: null
    },
    focusAccount: {
        value: {},
        loading: false,
        error: null
    },
}

export const AccountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        selectFocusAccount: (state, action) => {
            const accountId = action.payload
            state.focusAccount.value = state.myAccounts.value.find(account => account.id === accountId)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAccounts.pending, (state) => {
                state.myAccounts.loading = true
                state.myAccounts.error = null
            })
            .addCase(getAccounts.fulfilled, (state, action) => {
                state.myAccounts.loading = false
                state.myAccounts.value = action.payload
            })
            .addCase(getAccounts.rejected, (state, action) => {
                state.myAccounts.loading = false
                state.myAccounts.error = action.error.message
            })


        builder
            .addCase(createAccounts.pending, (state) => {
                state.myAccounts.loading = true
                state.myAccounts.error = null
            })
            .addCase(createAccounts.fulfilled, (state, action) => {
                state.myAccounts.loading = false
                state.myAccounts.value = [...state.myAccounts.value, ...action.payload]
            })
            .addCase(createAccounts.rejected, (state, action) => {
                state.myAccounts.loading = false
                state.myAccounts.error = action.error.message
            })
    },
})

// Action creators are generated for each case reducer function
export const { selectFocusAccount } = AccountSlice.actions
export const selectAccounts = (state) => state.account.myAccounts.value
export const selectLoading = (state) => state.account.myAccounts.loading
export const selectError = (state) => state.account.myAccounts.error
export default AccountSlice.reducer