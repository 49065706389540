import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ModelService from '../../services/ModelService'

export const getModels = createAsyncThunk('models/getModels', async (workspaceId) => {
    const models = await ModelService.getModels(workspaceId);
    return models
})

export const createModels = createAsyncThunk('models/createModels', async (models) => {
    const hydratedModels = await ModelService.putModels(models);
    return hydratedModels
})

export const deleteModel = createAsyncThunk('models/deleteModel', async (modelId) => {
    const obj = await ModelService.deleteModel(modelId);
    return obj?._id
})

const initialState = {
    myModels: {
        value: [],
        loading: false,
        error: null
    },
    focusModel: {
        value: {},
        loading: false,
        error: null
    },
}

export const ModelSlice = createSlice({
    name: 'model',
    initialState,
    reducers: {
        selectFocusModel: (state, action) => {
            const modelId = action.payload
            state.focusModel.value = state.myModels.value.find(model => model.id === modelId)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getModels.pending, (state) => {
                state.myModels.loading = true
                state.myModels.error = null
            })
            .addCase(getModels.fulfilled, (state, action) => {
                state.myModels.loading = false
                state.myModels.value = action.payload
            })
            .addCase(getModels.rejected, (state, action) => {
                state.myModels.loading = false
                state.myModels.error = action.error.message
            })


        builder
            .addCase(createModels.pending, (state) => {
                state.myModels.loading = true
                state.myModels.error = null
            })
            .addCase(createModels.fulfilled, (state, action) => {
                state.myModels.loading = false
                state.myModels.value = [...state.myModels.value, ...action.payload]
            })
            .addCase(createModels.rejected, (state, action) => {
                state.myModels.loading = false
                state.myModels.error = action.error.message
            })


        builder
            .addCase(deleteModel.fulfilled, (state, action) => {
                state.myModels.loading = false
                console.log('models', state.myModels.value?.map(model => model._id))
                console.log('deleteModel.fulfilled', action.payload)
                state.myModels.value = state.myModels.value.filter(model => {
                    console.log(model._id, 'vs', action.payload, model._id == action.payload)
                    return model._id != action.payload
                })
            })
            .addCase(deleteModel.rejected, (state, action) => {
                state.myModels.loading = false
                state.myModels.error = action.error.message
            })
    },
})

// Action creators are generated for each case reducer function
export const { selectFocusModel } = ModelSlice.actions
export const selectModels = (state) => state.model.myModels.value
export const selectLoading = (state) => state.model.myModels.loading
export const selectError = (state) => state.model.myModels.error
export default ModelSlice.reducer