import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import PriceService from '../../services/PriceService'

export const getWorkspacePrices = createAsyncThunk('workspaces/getPrices', async (workspaceId) => {
    const workspaceprices = await PriceService.getWorkspacePrices(workspaceId);
    return workspaceprices
})

const initialState = {
    value: [],
    loading: false,
    error: null
}

export const WorkspacePriceSlice = createSlice({
    name: 'workspaceprices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getWorkspacePrices.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(getWorkspacePrices.fulfilled, (state, action) => {
                state.loading = false
                state.value = action.payload
            })
            .addCase(getWorkspacePrices.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
    },
})

// Action creators are generated for each case reducer function
export const selectWorkspacePrices = (state) => state.workspaceprices.value
export const selectLoading = (state) => state.workspaceprices.loading
export const selectError = (state) => state.workspaceprices.error
export default WorkspacePriceSlice.reducer