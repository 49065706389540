
export const tierMetrics = [
    { "unit": "User", "suffix": "Per Workspace" },
    { "unit": "Prototype", "suffix": "Per Workspace" },
    { "unit": "Model Download" },
    { "unit": "Model Inference", "suffix": "Per Week" },
    { "unit": "Production Model" }
]

export const tierPriceFeatures = {
    "price_0": {
        "User": "1",
        "Prototype": "2",
        "Model Download": "1",
        icon: 'CustomRocketLaunch',
        color: 'warning'
    },
    "price_1N8ivrDsgCjmjz3tBFy0MiuC": {
        "User": "3",
        "Prototype": "5",
        "Model Download": "Unlimited",
        "Production Model": "1",
        "Model Inference": "10K",
        icon: 'Maps Home Work',
        color: 'primary'
    },
    "price_1N8iuSDsgCjmjz3thdG2hO7H": {
        "User": "20",
        "Prototype": "10",
        "Production Model": "5",
        "Model Download": "Unlimited",
        "Model Inference": "1M",
        icon: 'CustomFactory',
        color: 'info'
    },
    "price_1MUrtKDsgCjmjz3ts31vljQO": {
        "User": "3",
        "Prototype": "5",
        "Model Download": "Unlimited",
        "Production Model": "1",
        "Model Inference": "10K",
        icon: 'Maps Home Work',
        color: 'primary'
    },
    "price_1MUrxSDsgCjmjz3tCIgHbVzf": {
        "User": "20",
        "Prototype": "10",
        "Production Model": "5",
        "Model Download": "Unlimited",
        "Model Inference": "1M",
        icon: 'CustomFactory',
        color: 'info'
    }
}

export const freeTier = {
    "id": "price_0",
    "object": "price",
    "active": true,
    "billing_scheme": "per_unit",
    "created": 1684324368,
    "currency": "usd",
    "custom_unit_amount": null,
    "livemode": false,
    "lookup_key": "sample_basic",
    "metadata": {},
    "nickname": null,
    "product": {
        "name": "Free Workspace",
        "description": "A great way to get familiar with ai4us"
    },
    "recurring": {
        "interval": "month",
    },
    "unit_amount": 0,
}