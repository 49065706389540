import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UserService from '../../services/UserService'
import user6 from '../../assets/img/user6.png';
import Auth0ClientService from '../../services/Auth0ClientService'
import { store } from '../store'
import { getWorkspaces } from './WorkspaceSlice'
import { getModels } from './ModelSlice'
import { getAccounts } from './AccountSlice'
import { getModelTypes } from './ModelTypeSlice'
import { getDataTypes } from './DataTypeSlice'
import { getWorkspacePrices } from './WorkspacePriceSlice';
import a4Logger from '../../services/a4Logger';

export const getMe = createAsyncThunk('users/getUsers', async (dispatch, { getState }) => {

    try {
        const tempUserId = sessionStorage.getItem("USERID");
        var user = await Auth0ClientService.getUser()
        console.log("User retrieved", user)

        if (user) {
            var userData = await UserService.getUserData(user.user_id)
            user = { ...user, userData: userData }
            console.log("User data retrieved", user)
            UserService.me = user
        }
        else {
            await UserService.initialize()
            user = UserService.getMe()
        }

        // Merge users if old user is a temporary user and current user is not
        if (!user?.isFalseUser && tempUserId && tempUserId !== user.user_id) {
            console.log(`Merging users ${tempUserId} and ${user.user_id}`)
            await UserService.mergeUsers(tempUserId)
            console.log(`Merged users`)
            sessionStorage.removeItem("USERID");
        }

        console.log("User retrieved2", user)

        store.dispatch(getModels())
        store.dispatch(getAccounts())
        store.dispatch(getModelTypes())
        store.dispatch(getDataTypes())
        store.dispatch(getWorkspacePrices())
        await store.dispatch(getWorkspaces())

        // Initialize a set of workspaces if they do not exist
        const data = getState().workspace;
        console.log("Workspace data", data.myWorkspaces.value)

        if (data.myWorkspaces.value.length === 0) {
            console.log("Initializing workspaces")
            await UserService.initializeGuestUser(user?.user_id)
        }

        return user
    }
    catch (err) {
        a4Logger.logError("User login failed", err.message)
        return null
    }
})

export const updateUserData = createAsyncThunk('users/updateData', async (propertyNameValue, thunkAPI) => {

    console.log("Calling updateUserData", propertyNameValue)
    const state = thunkAPI.getState();
    var user = state.user.me.value;
    var userData = { ...user.userData, ...propertyNameValue }
    console.log("Updating user data", userData)
    await UserService.setUserData(userData)

    return userData
})

export const incrementModelCount = createAsyncThunk('users/incrementModelCount', async (_, thunkAPI) => {

    console.log("Incrementing model count")
    const state = thunkAPI.getState();
    console.log("Calling dispatch within thunk - state", state)
    var modelCount = state.user.me.value.userData.numModelsCreated;
    console.log("Calling dispatch within thunk - model count", modelCount)
    const userData = await thunkAPI.dispatch(updateUserData({ numModelsCreated: modelCount + 1 }));

    return userData
})

const initialState = {
    me: {
        value: {
            email: '',
            name: 'Guest',
            picture: user6,
            isFalseUser: true
        },
        loading: false,
        error: null
    },
    users: {
        value: {},
        loading: false,
        error: null
    },
}

export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginUser: (state, action) => {
            Auth0ClientService.login()
        },
        logoutUser: (state, action) => {
            Auth0ClientService.logout()
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMe.pending, (state) => {
                state.me.loading = true
                state.me.error = null
            })
            .addCase(getMe.fulfilled, (state, action) => {
                state.me.loading = false
                state.me.value = action.payload
            })
            .addCase(getMe.rejected, (state, action) => {
                state.me.loading = false
                state.me.error = action.error.message
            })

            .addCase(updateUserData.fulfilled, (state, action) => {
                state.me.loading = false
                var userData = action.payload
                state.me.value.userData = userData
            })
            .addCase(updateUserData.rejected, (state, action) => {
                state.me.loading = false
                state.me.error = action.error.message
            })
    },
})

// Action creators are generated for each case reducer function
export const { selectFocusUser } = UserSlice.actions
export const selectUsers = (state) => state.user.me.value
export const selectLoading = (state) => state.user.me.loading
export const selectError = (state) => state.user.me.error
export default UserSlice.reducer