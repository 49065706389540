import { useTour } from '@reactour/tour'
import React from 'react'
import start from '../../../../assets/img/humans_main-p-2600.png'
import Button from '../../../bootstrap/Button'
import { useNavigate } from 'react-router-dom'

export const PartnerSlide = () => {
    const { setIsOpen } = useTour();
    const navigate = useNavigate()
    return (
        <div className='row'>
            <div className='col-0 col-md-3 d-none d-md-block'>
                <img
                    width="300px"
                    src={start}
                />
            </div>
            <div className='col-12 col-md-9'>
                <h1>What Do You Want To See Next?</h1>
                <h2 style={{ fontWeight: 200 }}>
                    We're actively looking for early adopters and partners to help mold the platform. Use the contact portal below to leave feedback.
                    <br />
                    <br />
                    <a
                        target="_blank"
                        style={{ minHeight: "45px", display: "block", marginBottom: "30px" }}
                        href="mailto:support@ai4us.net">
                        <Button
                            color="success"
                            icon="EnvelopeFill"
                            className="pulse-button"
                        >
                            Email Us
                        </Button>
                    </a>
                </h2>
            </div>
        </div>
    )
}
