import React from 'react'
import Carousel from '../../bootstrap/Carousel'
import { NewModelIntro } from '../../models/NewModel/NewModelEasyWizard/NewModelIntro';
import { CreateAccountSlide } from './Slides/CreateAccountSlide';
import { useSelector } from 'react-redux';
import { TourSlide } from './Slides/TourSlide';
import { useTour } from '@reactour/tour';
import { GithubSlide } from './Slides/GithubSlide';
import { SupportSlide } from './Slides/SupportSlide';
import { PartnerSlide } from './Slides/PartnerSlide';

export const FeatureSlideshow = () => {

    const user = useSelector((state) => state.user.me.value)

    var items = [
        {
            src: <TourSlide />,
            altText: 'Slide 1',
            type: "html"
            // captionHeader: 'Slide 1 title',
            // captionText: 'Slide 1 text',
        },
        {
            src: <GithubSlide />,
            altText: 'Slide 2',
            type: "html"
            // captionHeader: 'Slide 1 title',
            // captionText: 'Slide 1 text',
        },
        {
            src: <SupportSlide />,
            altText: 'Slide 3',
            type: "html"
            // captionHeader: 'Slide 1 title',
            // captionText: 'Slide 1 text',
        },
        {
            src: <PartnerSlide />,
            altText: 'Slide 3',
            type: "html"
            // captionHeader: 'Slide 1 title',
            // captionText: 'Slide 1 text',
        },
    ];

    // If user is not logged in, start with logging in
    if (user?.isFalseUser) {
        items = [
            {
                src: <CreateAccountSlide />,
                altText: 'Slide 1',
                type: "html"
                // captionHeader: 'Slide 1 title',
                // captionText: 'Slide 1 text',
            },
            ...items
        ]
    }

    return (
        <div className='animate__animated animate__fadeIn'>
            <Carousel interval={10000} items={items} />
        </div>
    )
}
