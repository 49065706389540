import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import ThemeContext from '../contexts/themeContext';
import { Dots } from 'loading-animations-react';

import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { demoPages, layoutMenu } from '../menu';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { Auth0Provider } from "@auth0/auth0-react";
import { getMe } from '../redux/slices/UserSlice';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import { getAppInsights } from '../services/AppInsights/TelemetryService';
import TelemetryProvider from '../services/AppInsights/telemetry-provider';


const App = () => {
	getOS();
	const dispatch = useDispatch();
	const me = useSelector((state) => state.user.me);

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(async () => {

		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	useEffect(async () => {
		const me = await dispatch(getMe());
		console.log("User is now :", me);
	}, []);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [demoPages.login.path, demoPages.signUp.path, layoutMenu.blank.path];

	return (

		<TelemetryProvider instrumentationKey={process.env.REACT_APP_APP_INSIGHTS_KEY} after={() => { appInsights = getAppInsights() }}>
			<Auth0Provider
				domain="login.ai4us.net"
				clientId="s7KsXKo8OkmpF4LMglsF6VGoGo2hWQjm"
				redirectUri={`${window.location.origin}`}>
				<ThemeProvider theme={theme}>
					<ToastProvider components={{ ToastContainer, Toast }}>
						<TourProvider
							steps={steps}
							styles={styles}
							showNavigation={false}
							showBadge={false}>
							{
								me?.loading ? <PageWrapper className="justify-content-center text-center">
									<div className=" justify-content-center text-center" title="Project Dashboard">
										<Dots></Dots>
									</div>
								</PageWrapper> : <div
									ref={ref}
									className='app'
									style={{
										backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
										zIndex: fullScreenStatus && 1,
										overflow: fullScreenStatus && 'scroll',
									}}>
									<Routes>
										{withOutAsidePages.map((path) => (
											<Route key={path} path={path} />
										))}
										<Route path='*' element={<Aside />} />
									</Routes>
									<Wrapper />
								</div>
							}
							<Portal id='portal-notification'>
								<ReactNotifications />
							</Portal>
						</TourProvider>
					</ToastProvider>
				</ThemeProvider>

			</Auth0Provider >
		</TelemetryProvider>
	);
};

export default App;
