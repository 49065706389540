import { dummyWorkspaceData } from "../common/data/test_workspaceData";
import memoize from "memoizee";
import { getJson } from "./ServiceHelperMethods";

class SearchService {

    static memGetSearchImages = memoize(SearchService.getSearchImages, { async: true, maxAge: 1000 * 60 * 60 * 24 })

    static shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    static async getSearchImages(term) {
        console.log("Getting search images for term", term)
        const query = encodeURIComponent(term)
        return await getJson(`search/${query}`);
    }

    static async getSearchImagesForTerms(terms) {
        const imageLists = await Promise.all(terms.map(async (term) => (await SearchService.memGetSearchImages(term))))
        var images = imageLists.flat()
        SearchService.shuffleArray(images)
        return images
    }

}



export default SearchService;