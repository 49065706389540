import { configureStore } from '@reduxjs/toolkit'
import AccountReducer from './slices/AccountSlice'
import WorkspaceReducer from './slices/WorkspaceSlice'
import ModelReducer from './slices/ModelSlice'
import ModelTypeReducer from './slices/ModelTypeSlice'
import UserReducer from './slices/UserSlice'
import WorkspacePriceReducer from './slices/WorkspacePriceSlice'

export const store = configureStore({
    reducer: {
        account: AccountReducer,
        workspace: WorkspaceReducer,
        model: ModelReducer,
        modeltype: ModelTypeReducer,
        workspaceprices: WorkspacePriceReducer,
        user: UserReducer
    },
})

