import React from 'react'
import start from '../../../../assets/img/scene1.png'
import Button from '../../../bootstrap/Button'
import Auth0ClientService from '../../../../services/Auth0ClientService'

export const CreateAccountSlide = () => {
    return (
        <div className='row'>
            <div className='col-0 col-md-3 d-none d-md-block'>
                <img
                    width="300px"
                    src={start}
                />
            </div>
            <div className='col-12 col-md-9'>
                <h1>Login To Access Additional Features</h1>
                <h2 style={{ fontWeight: 200 }}>
                    Create an account to access more features and collaborate with teammates.
                    <br />
                    <br />
                    <Button
                        color="success"
                        icon="Person"
                        className="pulse-button"
                        onClick={() => Auth0ClientService.login()}
                    >
                        Login
                    </Button>
                </h2>
            </div>
        </div>
    )
}
