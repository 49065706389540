import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import data from './modelTypeInformation';
import Badge from '../../../../components/bootstrap/Badge';
import Card, { CardBody, CardTitle } from '../../../../components/bootstrap/Card';

import useDarkMode from '../../../../hooks/useDarkMode';
import useTourStep from '../../../../hooks/useTourStep';
import './NewModelTypeSelection.css'
import { demoPages } from '../../../../menu';
import Icon from '../../../icon/Icon';


// eslint-disable-next-line react/prop-types
const Item = ({ _id, image, title, description, tags, color, available, updateModelType, item }) => {
    useTourStep(15);
    const { darkModeStatus } = useDarkMode();

    const navigate = useNavigate();
    const handleOnClick = useCallback(
        () => updateModelType(item),
        [navigate, _id],
    );

    const navigateInfo = useCallback(
        (e) => {
            e.stopPropagation();
            navigate(`/${demoPages.knowledge.subMenu.itemID.path}/${_id}`);
        },
        [navigate, _id],
    );

    return (
        <Card
            className={classNames({
                'shadow-3d-primary shadow-3d-hover': available,
                'cursor-pointer': available,
                'disabled-model-option': !available,
            })}
            onClick={available ? handleOnClick : null}
            data-tour={title}>
            <CardBody>
                <CardTitle>{title} <Icon className="info-button" onClick={(e) => navigateInfo(e)} icon="InfoCircle" />
                </CardTitle>
                <div
                    className={classNames(
                        'ratio ratio-1x1',
                        'rounded-2',
                        `bg-l${darkModeStatus ? 'o25' : '10'}-${color}`,
                        'mb-3',
                    )}>
                    <img
                        src={image}
                        alt=''
                        width='100%'
                        height='auto'
                        className='object-fit-contain p-3'
                    />
                </div>
                <p className='text-muted'>{description}</p>
                {/* <div className='row g-2'>
                    {!!tags &&
                        // eslint-disable-next-line react/prop-types
                        tags.map((tag) => (
                            <div key={tag.text} className='col-auto'>
                                <Badge isLight color={tag.color} className='px-3 py-2'>
                                    {tag.text}
                                </Badge>
                            </div>
                        ))}
                </div> */}
            </CardBody>
        </Card>
    );
};


export const NewModelTypeSelection = ({ setModelType, updateModelType }) => {

    return (
        <div className='row'>
            {
                data.map((item) => (
                    <div key={item.id} className='col-xl-3 col-lg-4 col-md-6'>
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        <Item item={item} updateModelType={updateModelType} {...item} />
                    </div>
                ))
            }
        </div>
    )
}
