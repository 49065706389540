import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ModelTypeService from '../../services/ModelTypeService'

export const getModelTypes = createAsyncThunk('modeltypes/getModelTypes', async (workspaceId) => {
    const modeltypes = await ModelTypeService.getModelTypes(workspaceId);
    return modeltypes
})

const initialState = {
    value: [],
    loading: false,
    error: null
}

export const ModelTypeSlice = createSlice({
    name: 'modeltype',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getModelTypes.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(getModelTypes.fulfilled, (state, action) => {
                state.loading = false
                state.value = action.payload
            })
            .addCase(getModelTypes.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
    },
})

// Action creators are generated for each case reducer function
export const selectModelTypes = (state) => state.modeltype.value
export const selectLoading = (state) => state.modeltype.loading
export const selectError = (state) => state.modeltype.error
export default ModelTypeSlice.reducer