import Img1 from '../../../../assets/img/bird_recog.png';
import Img2 from '../../../../assets/img/bird_expert.png';
import Img3 from '../../../../assets/img/bird_predict.png';
import Img4 from '../../../../assets/img/bird_gen.png';
import Img5 from '../../../../assets/img/scene5.png';
import Img6 from '../../../../assets/img/scene6.png';
import COLORS from '../../../../common/data/enumColors';
import { expertModelDescription, ganModelDescription, imageRecongitionDescription, predictionModelDescription } from './content/modelTypeDescriptions';

const TAGS = {
	NPM: {
		text: 'NPM',
		color: COLORS.SUCCESS.name,
	},
	YARN: {
		text: 'Yarn',
		color: COLORS.DANGER.name,
	},
	BOOTSTRAP: {
		text: 'Bootstrap',
		color: COLORS.PRIMARY.name,
	},
	DEPENDENCIES: {
		text: 'Dependencies',
		color: COLORS.INFO.name,
	},
	VISUAL: {
		text: 'Visual',
		color: COLORS.INFO.name,
	},
	Video: {
		text: 'Video',
		color: COLORS.DANGER.name,
	},
	Image: {
		text: 'Images',
		color: COLORS.INFO.name,
	},
	Text: {
		text: 'Text',
		color: COLORS.DANGER.name,
	},
	New: {
		text: 'New',
		color: COLORS.SUCCESS.name,
	},
	Favorite: {
		text: 'Favorite',
		color: COLORS.SUCCESS.name,
	},
	GAN: {
		text: 'GAN',
		color: COLORS.WARNING.name,
	},
	LLM: {
		text: 'LLM',
		color: COLORS.WARNING.name,
	},
	SOON: {
		text: 'Coming Soon',
		color: COLORS.SUCCESS.name,
	}
};

export const CATEGORIES = {
	DOCUMENTATION: {
		value: 'documentation',
		text: 'Documentation',
	},
	SETTINGS: {
		value: 'settings',
		text: 'Settings',
	},
	COLORS: {
		value: 'colors',
		text: 'Colors',
	},
};

const data = [
	{
		_id: "63fbeb17b47d4b61fb58aa2b",
		title: 'Image Recognition',
		description: 'Build an image recognition model to integrate into your vision app. Easily identify objects in images and videos.',
		short: "Label objects in an image or video.",
		image: Img1,
		available: true,
		tags: [TAGS.Favorite, TAGS.Image, TAGS.Video],
		color: COLORS.WARNING.name,
		categories: [CATEGORIES.DOCUMENTATION, CATEGORIES.SETTINGS],
		InfoLink: '/',
		content:
			imageRecongitionDescription
	},
	{
		_id: 2,
		title: 'AI Expert (LLM)',
		description: 'Consume large sets of text or corpus such as laws and ask your new AI for summaries, key take aways, and insights.',
		short: "Asks questions about your large set of text and get summarized information and insights.",
		image: Img2,
		available: false,
		tags: [TAGS.SOON, TAGS.LLM, TAGS.Text],
		color: COLORS.PRIMARY.name,
		categories: [CATEGORIES.DOCUMENTATION],
		content: expertModelDescription

	},
	{
		_id: 3,
		title: 'Predict Outcomes',
		description:
			'Use tabular historical data in an Excel spreadsheet, dataframe, or CSV file to predict future values of a property.',
		short: "Predict future values of a property given historical data.",
		image: Img3,
		available: false,
		tags: [TAGS.SOON],
		color: COLORS.DANGER.name,
		categories: [CATEGORIES.DOCUMENTATION, CATEGORIES.SETTINGS],
		content: predictionModelDescription
	},
	{
		_id: 4,
		title: 'Create Media',
		description:
			'Generate art, music, and other forms of media by training this model on specific media examples.',
		image: Img4,
		short: "Predict future values of a property given historical data.",
		available: false,
		tags: [TAGS.SOON, TAGS.Image, TAGS.Video],
		color: COLORS.SUCCESS.name,
		categories: [CATEGORIES.DOCUMENTATION, CATEGORIES.SETTINGS],
		content:
			ganModelDescription
	},
];

export default data;
