import showNotification from '../components/extras/showNotification';

class a4Logger {

    static logError(message, submessage = '', shouldShowNotification = true) {
        console.error(message, ':\n', submessage);
        showNotification(
            message,
            submessage,
        )
    }

    static logMessage(message, submessage = '', shouldShowNotification = true) {
        showNotification(
            message,
            submessage,
        )
    }

    static notify(message, submessage = '') {
        showNotification(
            message,
            submessage,
        )
    }

    static debug(obj, message = "DebugMessage") {

        console.log(message);
        console.log(obj);
    }

    static console(message) {
        console.log(message);
    }

}


export default a4Logger; 