import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup';
import Wizard, { WizardItem } from '../Wizard';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../bootstrap/Card';
import { generateField, WizardField } from './WizardFields';
import Popovers from '../bootstrap/Popovers';
import Icon from '../icon/Icon';

const generateYup = (wizardObject) => {
    var yup = {}

    wizardObject?.steps?.map(s => {
        s?.cards?.map(c => {
            c?.fields?.map(f => {
                var key = f?.id
                var validate = f?.validate

                if (validate) {
                    yup[key] = validate
                }
            })
        })
    })

    return Yup.object().shape(yup)
}

const generateInitValues = (wizardObject) => {
    var init = {}
    const checks = []

    wizardObject?.steps?.map(s => {
        s?.cards?.map(c => {
            c?.fields?.map(f => {
                var key = f?.id
                var value = f?.init

                if (f?.type == "check") {
                    if (value) {
                        checks.push(key)
                    }
                }
                else {
                    init[key] = value
                }
            })
        })
    })

    init.checks = checks
    return init
}

export const WizardGenerator = ({ wizardObject, extIndex, setExtIndex }) => {

    const validate = generateYup(wizardObject)
    const initValues = generateInitValues(wizardObject)
    const [cardsCollapsed, setCardsCollapsed] = useState(null)
    const [displayErrors, setDisplayErrors] = useState(false)

    const formik = useFormik({
        initialValues: initValues,
        validationSchema: validate,
        onSubmit: (formData) => {
            wizardObject?.onSubmit(formData)
        },
    });

    useEffect(() => {
        const yup = generateYup(wizardObject)
        const values = generateInitValues(wizardObject)

        var cc = []
        wizardObject?.steps?.map(s => s?.cards?.map(card => {
            if (card?.collapsed) {
                cc.push(card?.title)
            }
        }))

        formik.setValues(values)
        setCardsCollapsed(cc)

    }, [wizardObject])

    const toggleCollapse = (cardTitle) => {

        var cc = cardsCollapsed

        if (cc?.includes(cardTitle)) {
            cc = cc.filter(e => e !== cardTitle);
        }
        else {
            cc.push(cardTitle)
        }

        setCardsCollapsed(cc)
    }

    const preSubmit = (event) => {
        setDisplayErrors(true)
        formik.handleSubmit(event)
    }


    return (
        <Wizard
            isHeader
            color='info'
            noValidate
            onSubmit={preSubmit}
            className='shadow-3d-info'
            extIndex={extIndex}
            setExtIndex={setExtIndex}
        >
            {
                wizardObject?.steps?.map(s => {
                    return <WizardItem title={s?.title ? s?.title : ""} icon={s?.icon} iconColor={s?.iconColor}>
                        {
                            s?.cards?.map(card => {
                                const isCollapsed = cardsCollapsed?.includes(card?.title)
                                return <Card isScrollable={false}>
                                    {card?.title &&
                                        <CardHeader>
                                            <CardLabel>
                                                <CardTitle>
                                                    <Icon
                                                        className="cursor-pointer"
                                                        icon={isCollapsed ? "ChevronDown" : "ChevronUp"}
                                                        onClick={() => toggleCollapse(card?.title)}
                                                    />{" "}
                                                    {card?.collapsed}
                                                    {card?.info &&
                                                        <Popovers
                                                            trigger='hover'
                                                            desc={card?.info}>
                                                            <Icon
                                                                icon='Info'
                                                                size='lg'
                                                                className='ms-1 mr-1 cursor-help'
                                                            />
                                                        </Popovers>
                                                    }
                                                    {card?.title}
                                                </CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                    }
                                    <CardBody>
                                        {
                                            !isCollapsed &&
                                            <div className='row g-4'>
                                                {
                                                    card?.fields?.map(f => {
                                                        return <WizardField fieldObject={f} formik={formik} />
                                                    })
                                                }
                                            </div>

                                        }
                                    </CardBody >
                                </Card>
                            })
                        }
                        {
                            displayErrors && Object.keys(formik.errors)?.length > 0 && <Card className="mt-3 mb-0">
                                <CardBody>
                                    {
                                        Object.keys(formik.errors).map((key) => {
                                            return <div className='row'>
                                                <div className='col'>
                                                    <span className="text-danger">
                                                        <Icon
                                                            icon='Info'
                                                            size='lg'
                                                            className='ms-1 mr-1 cursor-help'
                                                        />{formik.errors[key]}</span>
                                                </div>
                                            </div>
                                        })
                                    }
                                </CardBody>
                            </Card>
                        }
                    </WizardItem>
                })
            }
        </Wizard>
    )
}
