import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import user6 from '../../../assets/img/user6.png';
import USERS from '../../../common/data/userDummyData';
import Avatar from '../../../components/Avatar';
import Button from '../../../components/bootstrap/Button';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../components/bootstrap/Dropdown";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Auth0ClientService from "../../../services/Auth0ClientService";

const CommonHeaderChat = () => {
	const [state, setState] = useState(false);
	const [dropDownToggleOpen, setDropDownToggleOpen] = useState(false)

	const [msgCount, setMsgCount] = useState(0);

	const me = useSelector((state) => state.user.me);

	const navigate = useNavigate();
	const setDropDownState = (isOpen) => {
		setDropDownToggleOpen(isOpen)
	}

	return (
		<>
			<div
				id="profile-dropdown"
				data-tour="profile-dropdown"
				className='col d-flex align-items-center cursor-pointer'
				onClick={() => setState(!state)}
				role='presentation'>
				{
					(me?.value && !me?.value.isFalseUser) ?
						<>

							<div className='me-3'>
								<div className='text-end'>
									<div className='fw-bold fs-6 mb-0'>
										{me?.value.name}
									</div>
									<div className='text-muted'>
										<small>Engineer</small>
									</div>
								</div>
							</div>
							<div className='position-relative'>
								<Dropdown isOpen={dropDownToggleOpen}>
									<DropdownToggle hasIcon={false}>
										<Avatar
											srcSet={me?.value.picture}
											src={me?.value.picture}
											size={48}
											color={USERS.CHLOE.color}
										/>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem onClick={() => Auth0ClientService.logout({ returnTo: window.location.origin })}>
											Logout
										</DropdownItem>
										<DropdownItem key="btn-logout">
											<Button
												onClick={() => navigate(`/appointment/employee/1`)}>
												Update Profile
											</Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								{!!msgCount && (
									<span className='position-absolute top-15 start-85 translate-middle badge rounded-pill bg-danger'>
										{msgCount} <span className='visually-hidden'>unread messages</span>
									</span>
								)}
								<span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
									<span className='visually-hidden'>Online user</span>
								</span>
							</div>
						</>
						:
						<>
							<Button
								color='success'
								icon="Person"
								onClick={() => { Auth0ClientService.login() }}
							>Log In</Button>
						</>
				}
			</div>
		</>
	);
};

export default CommonHeaderChat;
