import { useTour } from '@reactour/tour'
import React from 'react'
import start from '../../../../assets/img/wanna/landing2.png'
import Button from '../../../bootstrap/Button'
import { useNavigate } from 'react-router-dom'

export const GithubSlide = () => {
    const { setIsOpen } = useTour();
    const navigate = useNavigate()
    return (
        <div className='row'>
            <div className='col-0 col-md-3 d-none d-md-block'>
                <img
                    width="240px"
                    src={start}
                />
            </div>
            <div className='col-12 col-md-9'>
                <h1>Check Out Our Samples</h1>
                <h2 style={{ fontWeight: 200 }}>
                    While you wait, clone our example repo to access working examples of apps leveraging ai4us models via our REST interface.
                    <br />
                    <br />
                    <a
                        target="_blank"
                        style={{ minHeight: "45px", display: "block", marginBottom: "30px" }}
                        href="https://github.com/gas6262/ai4us_samples/tree/main/samples/image_recognition/reactapp">
                        <Button
                            color="success"
                            icon="Github"
                            className="pulse-button"
                        >
                            Go To Examples
                        </Button>
                    </a>
                </h2>
            </div>
        </div>
    )
}
