import React from 'react'
import { selectWorkspacePrices } from '../../../redux/slices/WorkspacePriceSlice';
import { useSelector, useDispatch } from 'react-redux';
import { freeTier, metrics, tierMetrics, tierPriceFeatures } from './tierPriceFeatures';
import Card, { CardBody, CardHeader } from '../../bootstrap/Card';
import Icon from '../../icon/Icon';
import Button from '../../bootstrap/Button';

export const PriceSelector = ({ setPlan }) => {

    const prodPriceTiers = useSelector(selectWorkspacePrices);
    const allTiers = [freeTier, ...prodPriceTiers]

    const featuredProps = { borderColor: 'primary', borderSize: 3, shadow: 'lg' }

    return (
        <div>
            <div id='second' className='row'>
                {
                    allTiers.map((tier, index) => {
                        const tierPriceFeature = tierPriceFeatures[tier?.id]
                        console.log('tierPriceFeatures', tierPriceFeatures, "cur: ", tierPriceFeature, "ID:", tier?.product?.id, "All tiers:", allTiers)

                        return <div className='col-md-4'>
                            <Card stretch {...(index == 1 ? featuredProps : {})}>
                                <CardBody>
                                    <div className='row pt-5 g-4 align-items-center'>
                                        <div className='col-auto'>
                                            <Icon icon={tierPriceFeature?.icon} size='5x' color={tierPriceFeature?.color} />
                                        </div>
                                        <div className='col'>
                                            <h2>{tier?.product?.name}</h2>
                                        </div>
                                        <div className='col-12'>
                                            <h3 className='display-1 fw-bold'>
                                                {
                                                    tier?.unit_amount == 0 ? <>
                                                        Free
                                                    </> : <>
                                                        <span className='display-4 fw-bold'>$</span>{tier?.unit_amount / 100}
                                                        <span className='display-6'>/mo</span>
                                                    </>
                                                }
                                            </h3>
                                        </div>
                                        <div className='col-12'>
                                            {
                                                tierMetrics.map(metric => {
                                                    const metricValue = tierPriceFeature[metric.unit]

                                                    return (metricValue ? <div className='lead'>
                                                        <Icon icon='Done Outline' color='success' /> <span className='h5'>{metricValue}</span> {metric.unit}{metricValue == "1" ? "" : "s"} {metric?.suffix}
                                                    </div> :
                                                        <div className='lead'>
                                                            <Icon icon='Close' color='danger' /> No {metric.unit}s
                                                        </div>)

                                                })
                                            }
                                        </div>
                                        <div className='col-12'>
                                            <p>{tier.product.description}</p>
                                        </div>
                                        <div className='col-12'>
                                            {
                                                tier?.unit_amount == 0 ? <></> :
                                                    <Button
                                                        color='info'
                                                        isLight
                                                        className='w-100 py-3 text-uppercase'
                                                        size='lg'
                                                        onClick={() => setPlan(tier)}
                                                    >
                                                        Select Plan
                                                    </Button>
                                            }
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    })
                }
            </div>
        </div>
    )
}
