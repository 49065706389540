import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import WorkspaceService from '../../../services/WorkspaceService';
import { UpgradeWorkspaceWizard } from '../../../components/workspaces/UpgradeWorkspaceWizard.js/UpgradeWorkspaceWizard';

export const UpgradeWorkspacePage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [workspace, setWorkspace] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const navigate = useNavigate();
    var workspaceId = searchParams.get("workspace")
    var modelId = searchParams.get("model")

    const completeUpgrade = () => {
        if (modelId) {
            navigate(`/models/${modelId}`)
            return
        }

        navigate(`/models?workspace=${workspace?._id}`)
    }

    useEffect(async () => {
        setIsLoading(true)
        if (workspaceId == null) {
            setIsLoading(false)
            setError(new Error("No workspace provided"))
            return
        }

        try {
            const workspace = await WorkspaceService.getWorkspace(workspaceId);
            setWorkspace(workspace)
        }
        catch (err) {
            console.log(err)
            setError(err)
        }
        setIsLoading(false)

    }, [workspaceId])

    return (
        <PageWrapper title={`Upgrade Workspace ${workspace?.title}`}>
            <Page container='fluid'>
                {
                    isLoading ? <div>Loading...</div> :
                        error ? <div>Something went wrong <br /> {error.message}</div> :
                            workspace ? <UpgradeWorkspaceWizard workspace={workspace} completeUpgrade={completeUpgrade} /> : <div>Workspace not found</div>
                }
            </Page>
        </PageWrapper >
    )
}
