import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import WorkspaceService from '../../services/WorkspaceService'

export const getWorkspaces = createAsyncThunk('workspaces/getWorkspaces', async () => {
    console.log("Getting the workspaces")
    const workspaces = await WorkspaceService.getWorkspaces();
    console.log("Workspaces retrieved?")
    return workspaces
})

export const createWorkspaces = createAsyncThunk('workspaces/createWorkspaces', async (workspaces) => {
    const hydratedWs = await WorkspaceService.createWorkspaces(workspaces);
    return hydratedWs
})

export const deleteWorkspace = createAsyncThunk('workspaces/deleteWorkspace', async (workspaceId) => {
    const ws = await WorkspaceService.deleteWorkspace(workspaceId);
    return ws?._id
})

const initialState = {
    myWorkspaces: {
        value: [],
        loading: false,
        error: null
    },
    focusWorkspace: {
        value: {},
        loading: false,
        error: null
    },
    myWorkspaceMap: {
        value: {},
        loading: false,
        error: null
    }
}

export const WorkspaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        selectFocusWorkspace: (state, action) => {
            const workspaceId = action.payload
            state.focusWorkspace.value = state.myWorkspaces.value.find(workspace => workspace.id === workspaceId)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWorkspaces.pending, (state) => {
                state.myWorkspaces.loading = true
                state.myWorkspaces.error = null
            })
            .addCase(getWorkspaces.fulfilled, (state, action) => {
                state.myWorkspaces.loading = false
                state.myWorkspaces.value = action.payload
                var m = {}
                action.payload.forEach(ws => {
                    m[ws._id] = ws
                })
                state.myWorkspaceMap.value = m
            })
            .addCase(getWorkspaces.rejected, (state, action) => {
                state.myWorkspaces.loading = false
                state.myWorkspaces.error = action.error.message
            })


        builder
            .addCase(createWorkspaces.pending, (state) => {
                state.myWorkspaces.loading = true
                state.myWorkspaces.error = null
            })
            .addCase(createWorkspaces.fulfilled, (state, action) => {
                state.myWorkspaces.loading = false
                state.myWorkspaces.value = [...state.myWorkspaces.value, ...action.payload]
                var m = {}
                action.payload.forEach(ws => {
                    m[ws._id] = ws
                })
                state.myWorkspaceMap.value = m
            })
            .addCase(createWorkspaces.rejected, (state, action) => {
                state.myWorkspaces.loading = false
                state.myWorkspaces.error = action.error.message
            })

        builder
            .addCase(deleteWorkspace.fulfilled, (state, action) => {
                state.myWorkspaces.loading = false
                console.log('models', state.myWorkspaces.value?.map(workspace => workspace._id))
                console.log('deleteWorkspace.fulfilled', action.payload)
                state.myWorkspaces.value = state.myWorkspaces.value.filter(workspace => {
                    console.log(workspace._id, 'vs', action.payload, workspace._id == action.payload)
                    return workspace._id != action.payload
                })

                delete state.myWorkspaceMap.value[action.payload]
            })
            .addCase(deleteWorkspace.rejected, (state, action) => {
                state.myWorkspaces.loading = false
                state.myWorkspaces.error = action.error.message
            })
    },
})

// Action creators are generated for each case reducer function
export const { selectFocusWorkspace } = WorkspaceSlice.actions
export const selectWorkspaces = (state) => state.workspace.myWorkspaces.value
export const selectLoading = (state) => state.workspace.myWorkspaces.loading
export const selectError = (state) => state.workspace.myWorkspaces.error
export default WorkspaceSlice.reducer