import createAuth0Client from '@auth0/auth0-spa-js';

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}


class Auth0ClientService {
    static auth0ClientProm = null;

    static resetClient() {
        console.log(`Resetting the auth0 client, `, process.env.REACT_APP_AUTH0_DOMAIN, process.env.REACT_APP_AUTH0_CLIENT_ID)
        Auth0ClientService.auth0ClientProm = createAuth0Client({
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
            audience: "https://ai4us.net/api",
            authorizationParams: {
                redirect_uri: `${window.location.origin}`,
            }
        })
    }

    static {
        console.log(`Initializing auth0 client for Auth0ClientService`)
        Auth0ClientService.resetClient()
    }

    static async getUser(userId = null) {
        var user = null
        var retries = 7

        while (!user && retries-- > 0) {
            console.log(`Auth0ClientService.getUser() called with userId: `, userId, ` and retries: `, retries)

            var auth0client = await (createAuth0Client({
                domain: process.env.REACT_APP_AUTH0_DOMAIN,
                client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
                redirect_uri: `${window.location.origin}`,
            }))

            user = await auth0client.getUser()
            if (user) {
                user = {
                    ...user,
                    user_id: user?.sub
                }
            }
            if (!user) {
                await delay(70);
            }
        }

        console.log(`Auth0ClientService.getUser() returning user: `, user, ` with retries: `, retries)
        return user
    }

    static async login(redirect_uri = '') {
        var auth0client = await (Auth0ClientService.auth0ClientProm)
        const fullUrl = `${window.location.origin}${redirect_uri}`
        await auth0client.loginWithRedirect({
            redirect_uri: fullUrl
        });
    }

    static async logout() {
        var auth0client = await (Auth0ClientService.auth0ClientProm)
        await auth0client.logout();
    }

    static async getAuthToken(audience, scopes = null) {

        console.log(`Auth0ClientService.getAuthToken() called with audience: `, audience, ` and scopes: `, scopes)

        var accessToken = null
        var auth0client = await (createAuth0Client({
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
            redirect_uri: `${window.location.origin}`,
            audience: "https://ai4us.net/api",
        }))

        console.log(`Auth0ClientService.getAuthToken() got auth0client: `, auth0client)

        try {
            accessToken = await auth0client.getTokenSilently({
                audience: "https://ai4us.net/api",
                redirect_uri: `${window.location.origin}`,
            });
        } catch (error) {
            if (error.error !== 'login_required') {
                accessToken = await auth0client.getTokenWithPopup({
                    audience: "https://ai4us.net/api",
                    redirect_uri: `${window.location.origin}`,
                });
            }
        }

        console.log(`Auth0ClientService.getAuthToken() returning token: `, accessToken)
        return accessToken
    }
}

export default Auth0ClientService;