import React, { useEffect, useState } from 'react'
import Select from '../../bootstrap/forms/Select'
import Input from '../../bootstrap/forms/Input'
import { Dots } from 'loading-animations-react';
import { loadStripe } from '@stripe/stripe-js';

import {
    CardElement,
    useStripe,
    useElements,
    Elements,
} from '@stripe/react-stripe-js';
import Button from '../../bootstrap/Button';
import Card, {
    CardActions,
    CardBody,
    CardFooter,
    CardFooterLeft,
    CardHeader,
    CardLabel,
    CardSubTitle,
    CardTitle,
    CardFooterRight
} from '../../bootstrap/Card';
import a4Logger from '../../../services/a4Logger';
import WorkspaceService from '../../../services/WorkspaceService';
import { useDispatch } from 'react-redux';
import { getWorkspaces } from '../../../redux/slices/WorkspaceSlice';
import { getModels } from '../../../redux/slices/ModelSlice';
import Icon from '../../icon/Icon';

export const WorkspacePaymentSelector = ({ workspace, tier, setIsLoading, setStepNumber, stripeContext, completePayment }) => {
    const [fullName, setFullName] = useState(null)
    const { subscriptionId, clientSecret, publishableKey, paymentMethods } = stripeContext
    const dispatch = useDispatch();

    // Initialize an instance of stripe.
    const stripe = useStripe();
    const elements = useElements();

    const margin = `mb-2 mt-2`
    const classLeft = `col-sm-3 col-12 col-lg-2 h4 ${margin}`
    const classRight = `col-sm-9 col-12 col-lg-10 h5 ${margin}`

    const submitPayment = async (cardElement, fullName) => {

        try {

            // Use card Element to tokenize payment details
            let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: fullName,
                    }
                }
            });

            setIsLoading(true)

            if (error) {
                // show error and collect new card details.
                a4Logger.logError("Payment failed", error.message);
            }
            else {
                await WorkspaceService.completeUpgrade(workspace, paymentIntent, subscriptionId);
                dispatch(getWorkspaces())
                dispatch(getModels())
                await new Promise(resolve => setTimeout(resolve, 2000));
                if (completePayment) {
                    completePayment()
                }
            }

        }
        catch (error) {
            a4Logger.logError("An error occurred", error.message)
        }
        setIsLoading(false)
    }


    // When the subscribe-form is submitted we do a few things:
    //
    //   1. Tokenize the payment method
    //   2. Create the subscription
    //   3. Handle any next actions like 3D Secure that are required for SCA.
    const handleSubmit = async () => {

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
        submitPayment(cardElement, fullName)
    }

    return (
        <div>
            <Card shadow='none' borderSize={1} className='rounded-2'>
                <CardHeader>
                    <CardLabel>
                        <CardTitle class="h3">
                            <Icon icon="CreditCard" color="success" size="3x" /> Complete Payment
                        </CardTitle>
                    </CardLabel>
                </CardHeader>
                <CardBody className='pt-0'>
                    <div class="row">
                        <div class={classLeft}>Workspace</div>
                        <div class={classRight}>{workspace.title}
                        </div>
                        <div class={classLeft}>Plan</div>
                        <div class={classRight}>
                            {tier?.product?.name}
                        </div>
                        <div class={classLeft}>Price</div>
                        <div class={classRight}>
                            ${tier?.unit_amount / 100}/month
                        </div>
                        <div class={classLeft}>Full Name</div>
                        <div class={classRight}>
                            <Input
                                type="text"
                                onChange={(e) => setFullName(e.target.value)}
                                value={fullName}
                            />
                        </div>
                        <div class={classLeft}>Payment Method</div>
                        <div class={classRight}>
                            <CardElement />
                        </div>
                    </div>
                </CardBody>
                <CardFooter className='pt-0' size='sm'>
                    <CardFooterLeft>
                    </CardFooterLeft>
                    <CardFooterRight>
                        <Button
                            color='success'
                            icon='Check'
                            onClick={handleSubmit}
                        >Submit</Button>
                    </CardFooterRight>
                </CardFooter>
            </Card>
        </div>
    )
}
