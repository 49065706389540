import React from 'react'
import start from '../../../../assets/img/wanna/richie/start.png'
import Icon from '../../../icon/Icon'

export const NewModelIntro = () => {
    return (
        <div className='row'>
            <div className='col-0 col-sm-2 d-none d-sm-block animate__animated animate__fadeInLeft'>
                <img
                    width="50%"
                    max-height="50%"
                    src={start}
                />
            </div>
            <div className='col-12 col-sm-10 animate__animated animate__fadeIn'>
                <h1>Let's Build Your New Model!</h1>
                <h4 style={{ fontWeight: 200 }}>
                    Using this wizard, you can easily create a new AI model in a few easy steps. First, select a model type. Then, ai4us can help you find the right data to train your model or you can provide your own. Finally, you can train your model and deploy it to the cloud. <Icon color="success" icon="RocketTakeoff" />

                    <br />
                    <br />
                    Click next below to get started!
                </h4>
            </div>
        </div>
    )
}
